import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';

const useCollections = () => {
  const [collections, setCollections] = useState([]);
  const [current_collection, setCurrentCollection] = useState([]);

  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores

  const fetchCollections = async (collections) => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setCollections(() => []);
        const { data, error: apiError } = await apiRequest(
            '/collections',
            'GET',
            'include',
            'application/json',
            null,
            false
        );

        if (apiError) {
            throw new Error(apiError.message);
        }
        
        if (data && Array.isArray(data)) {
          setCollections(data);
          setCurrentCollection(data[0]);
        }
        

    } catch (error) {
        console.error('Error fetching collections:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  return {
    collections,
    current_collection,
    loading,
    fetchCollections,
  };
};

export default useCollections;
