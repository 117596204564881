import React, { useEffect, useState } from 'react';
import "./LoginForm.css";
import { BACKEND_URL_DJANGO } from '../../../services/api/api-urls';
import apiRequest from '../../../services/api/apiRequest';
import { useUserContext } from '../../../contexts/userContext';
import Footer from '../../containers/Footer/Footer';

const default_client = {
  name: "Pomelo",
  logo: require('../../../assets/images/logo-pomelo.png'),
  landing_image: require('../../../assets/images/default-landing-image.png'),
  custom_css: {
  },
  app_name: "Pomelo"
}

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { dispatchUser } = useUserContext();
  const [client, setClient] = useState(default_client);
  
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BACKEND_URL_DJANGO}/api/v1/token/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password: password }), // Corregido para enviar un objeto JSON
      });

      if (!response.ok) {
        throw new Error('Credenciales incorrectas. Por favor, intenta de nuevo.');
      }
      // Cargar el usuario logueado con su configuración
      const userRequest  = await apiRequest('/users/me/', 'GET', 'include', 'application/json', null);
      const settingsRequest  = await apiRequest('/settings/', 'GET', 'include', 'application/json', null);
      // Combinar ambas respuestas en un único objeto
      const user = { 
        ...userRequest.data, 
        ...settingsRequest.data 
      };

      // Actualizar el contexto con el objeto combinado
      dispatchUser({ type: 'set', user: user });
      // Redireccionar a la página de chat
      window.location.href = '/chat';

    } catch (err) {
      // Si hay un error, establecer el mensaje de error
      setError(err.message || 'Ocurrió un error inesperado. Por favor, intenta de nuevo.');
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const client_id = queryParams.get('client');
  
    if (client_id) {
      const fetchFeedback = async () => {
        try {
          setClient({}); // Limpiar bookmarks anteriores
          const { data, error: apiError } = await apiRequest(
            `/clients/${client_id}`,
            'GET',
            'include',
            'application/json'
          );
  
          if (apiError) {
            setClient(default_client)
          }
  
          if (data) {
            setClient(data);
            const cssConfig = data?.custom_css;
            if (cssConfig && typeof cssConfig === "object") {
              Object.entries(cssConfig).forEach(([key, value]) => {
                document.body.style.setProperty(key, value);
              });
            }
      
          }
        } catch (error) {
          console.error('Error fetching feedback:', error);
        } finally {
        }
      };
  
      fetchFeedback();
    }
  }, []);
  
  useEffect(() => {
    dispatchUser({type:'reset'})
  },[])
  
  return (
    <div className="login-page">
      <div className="content" style={{ backgroundImage: `url(${client?.landing_image})` }}>
        <div className="container">
          <div className="col-sm-6 login">
            <img 
              // src={user?.client?.logo || require('../../../assets/images/logo-pomelo.png')}
              src={require('../../../assets/images/logo-IA4Doctors-powered.png')}
              alt="Pomelo Logo" 
              className="login-logo" 
            />
            <div className="modal-login">
              <h2 className="title-login">Iniciar sesión</h2>
              <p className="subtitle-login">Introduce tus credenciales para acceder a {client?.app_name || "Pomelo"} de forma segura.</p>
              {error && <p className="error">{error}</p>}
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="username">Correo electrónico</label>
                  <input
                    type="text"
                    className="form-log"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder='Introduzca su correo electrónico'
                  />
                </div>
                <div>
                  <label htmlFor="password">Contraseña</label>
                  <input
                    type="password"
                    className="form-log"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder='Introduzca su contraseña'
                  />
                </div>
                <button className="login_cta" type="submit">Iniciar sesión</button>
              </form>
              <div className='login_forgot'>
                <a href="" target="_blank">¿Olvidó su contraseña?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default LoginForm;
