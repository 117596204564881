import React, { useEffect, useState, useRef } from 'react';
import './CollectionInputModal.css';
import { RiCloseFill } from "react-icons/ri";
import FormInput from '../FormInput/FormInput';
import { useCollectionsContext } from '../../../contexts/collectionsContext';

const CollectionInputModal = ({ onClose }) => {
    const { collections, current_collection, dispatchCollections } = useCollectionsContext();
    const [changeCollectionToggle, setChangeCollectionToggle] = useState(false);
    const modalRef = useRef(null); // Referencia para el modal

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose(); // Cierra el modal si se hace clic afuera
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    const handleCollectionChange = (collection_id) => {
        dispatchCollections({ type: "SET_CURRENT_COLLECTION", payload: collection_id });
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-filters" ref={modalRef}>
                <div className="modal-header">
                    <h2>Selecciona la colección</h2>
                    <button className="close-btn" onClick={onClose}>
                        <RiCloseFill size={24} />
                    </button>
                </div>

                <div className="modal-field-selection">
                    <FormInput
                        name={"collection-select"}
                        type={"select"}
                        label={"Colección"}
                        options={collections.map((collection) => ({
                            label: collection.verbose_name,
                            value: collection.id,
                        }))}
                        placeholder={"Selecciona la colección"}
                        helptext={"Colección de documentos"}
                        initial_value={{
                            label: current_collection?.verbose_name,
                            value: current_collection?.id,
                        }}
                        onChange={(e) => handleCollectionChange(e.value)}
                    />
                </div>

                <div className="modal-footer"></div>
            </div>
        </div>
    );
};

export default CollectionInputModal;
