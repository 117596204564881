// ChatInputTools.js
import React, { useEffect, useRef, useState } from 'react';
import './ChatInputTools.css'
import { FaPlus } from 'react-icons/fa';
import FormInput from '../FormInput/FormInput';
import FiltersInputModal from '../FiltersInputModal/FiltersInputModal';
import CollectionInputModal from '../CollectionInputModal/CollectionInputModal';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import Select from 'react-select'; // Asegúrate de tener react-select instalado
import CitationIcon from '../../icons/CitationIcon';

const MultiValueLabel = (props) => {
  return (
    <CitationIcon number={props.data.indexLabel}></CitationIcon>
  );
};

const ChatInputTools = ({
    intention,
    current_collection,
    MetadatasToggle,
    handleMetadatasToggle,
    metadatas,
    // externalSearchFormValues,
    preFetchLoading,
    currentResultsCount,
    currentSourceHref,
    handleExternalSearchSubmit,
    handleInputChange,
    externalSearchFormSendEnabled,
  }) => {
    const [visibleSelectedDocuments, setVisibleSelectedDocuments] = useState({})
    const { documents, dispatchDocuments } = useDocumentsContext();
    const [changeCollectionToggle, setChangeCollectionToggle] = useState(false);
    const [selectDocumentsToggle, setSelectDocumentsToggle] = useState(false);
    const selectRef = useRef(null);
    
    const handleCloseCollectionInputModal = () => {
      setChangeCollectionToggle(false)
    };
    
    const handleOpenCollectionInputModal = () => {
      setChangeCollectionToggle(true)
    }; 

    const handleOpenDocsDropdown = () => {
      setSelectDocumentsToggle(true)
    };
    const handleCloseDocsDropdown = () => {
      setSelectDocumentsToggle(false)
    };
    
    const handleDocumentChange = (selectedDocuments) => {
      const filteredEntries = Object.values(documents).filter(entry => entry.selected === true);
      setVisibleSelectedDocuments(filteredEntries);
      dispatchDocuments({ type: 'select', selectedDocuments });
      return filteredEntries;
    };
    
    const getSelectedCount = (docs) => {
      const count = Object.values(docs).filter(doc => doc.selected).length
      return count;
    };
  

    useEffect(() => {
      const filteredEntries = Array.from(documents.selected_documents);
      setVisibleSelectedDocuments(filteredEntries);
    }, [documents.selected_documents]);
    


    return (
      
      <>
        {intention === "rag_question" && (
          <>
            <button className="chat-tools-btn" onClick={handleOpenCollectionInputModal}>
              <strong>Colección:</strong> {current_collection?.verbose_name || "Cargando colecciones..."}
            </button>

            {changeCollectionToggle && (
              <CollectionInputModal onClose={handleCloseCollectionInputModal} />
            )}

            {MetadatasToggle && <FiltersInputModal onClose={handleMetadatasToggle} />}

            {metadatas?.selectedMetadatas &&
              Object.entries(metadatas.selectedMetadatas).map(([key, { label, values }]) =>
                label && values?.length > 0 ? (
                  <button className="chat-tools-btn" key={key} onClick={handleMetadatasToggle}>
                    <strong>{label}:</strong> {values.map((val) => val.label).join(" | ")}
                  </button>
                ) : null
              )}

            <button className="filter-btn chat-tools-btn" onClick={handleMetadatasToggle}>
              <FaPlus width={15} height={15} />
            </button>
          </>
        )}

        {intention === "doc_question" && (
        <>
        <div className="documents-selector">

        {!selectDocumentsToggle && (
        <div className='documents-selector-icons'>
          {Object.keys(visibleSelectedDocuments).length === 0 ? (
            <p>Seleccione al menos un documento.</p>
          ) : (
            <>
              <p>Documentos seleccionados:</p>
              {Object.entries(visibleSelectedDocuments).map(([key, value]) => (
                <div key={key}>
                  <CitationIcon number={documents.documents[value].index} doc={documents.documents[value].value} />
                </div>
              ))}
            </>
          )}
          {/* <CitationIcon number={"+"} onClick={handleOpenDocsDropdown} /> */}
        </div>
      )}

        { selectDocumentsToggle && (
            <Select
            className="chat-tools-dropdown"
            options={Object.entries(documents).map(([id, doc]) => ({
              value: id,
              label:`[${doc.index}] ${doc.metadata.title} `,
              indexLabel: doc.index
            }))}
            placeholder="Selecciona un documento"
            onChange={(selectedDocuments) => handleDocumentChange(selectedDocuments)}
            isMulti={true}
            components={{ MultiValueLabel }} // Personaliza la selección
            menuPlacement="top"
          />
        )}
        </div>
        </>

        )}

        {/* {intention === "external_search" && (
          <form className="external-search-form" onSubmit={handleExternalSearchSubmit}>
            <FormInput
              name="source"
              type="select"
              label="Source"
              value={externalSearchFormValues.source}
              onChange={(e) => handleInputChange("source", e.value)}
              options={[
                { label: "Pubmed", value: "pubmed" },
                { label: "CIMA (Aemps)", value: "cima" },
              ]}
            />
            <FormInput
              name="term"
              type="textarea"
              label="Term"
              value={externalSearchFormValues.term}
              wait_time={1000}
              onChange={(e) => handleInputChange("term", e)}
            />
            <p>{preFetchLoading ? "Cargando..." : `${currentResultsCount} resultados`}</p>
            {currentSourceHref && (
              <a target="_blank" href={currentSourceHref} rel="noopener noreferrer">
                Visualizar en fuente
              </a>
            )}
            <button
              type="submit"
              className="external-search-send-btn"
              disabled={!externalSearchFormSendEnabled}
            >
              Añadir
            </button>
          </form>
        )} */}
      </>
    );
  };
  
export default ChatInputTools;
