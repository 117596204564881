import './Account.css';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/containers/Sidebar/Sidebar';
import Header from '../../components/containers/Header/Header';
import Footer from '../../components/containers/Footer/Footer';
import AccountArea from '../../components/containers/AccountArea/AccountArea';

const Account = () => {
  // const { bookmarks, loading, fetchBookmarks } = useBookmark();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  // useEffect(() => {
  //   fetchBookmarks();  // Cargar bookmarks solo una vez al montar el componente
  // }, []); 

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  return (
  <div className={`chat-container ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
  <Sidebar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
  <div className={`chat-content ${!isSidebarVisible ? 'full-width' : ''}`}>
    <Header subtitle={"Mi cuenta"} toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
    <div className='chat-preview-container full-width'>
    <AccountArea/>
    </div>
    <Footer toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
  </div>
  </div>
  );
};
export default Account;