import React from 'react';
import { ReactComponent as PomeloIcon } from '../../../assets/images/icons/icon-pomelo.svg';
import "./BasicLoader.css";

const BasicLoader = () => {
  return (
    <div className='loader flex flex-c'>
      <PomeloIcon/>
    </div>
  )
}

export default BasicLoader