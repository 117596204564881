import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // GitHub flavored markdown
import { useDocumentsContext } from '../../../contexts/documentsContext';
import './MessageTextBox.css';
import CitationIcon from '../../icons/CitationIcon';

const CopyCodeButton = ({ code }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopy = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopySuccess('¡Código copiado!');
        setTimeout(() => setCopySuccess(''), 2000);
      })
      .catch(() => {
        setCopySuccess('Error al copiar');
      });
  };

  return (
    <button onClick={handleCopy} className="copy-code-button">
      {copySuccess || 'Copiar'}
    </button>
  );
};

const CodeBlock = ({ inline, children, className }) => {
  if (inline) {
    return <code className={className}>{children}</code>; // For inline code
  }

  const hasMultipleLines = children.toString().includes('\n');

  return (
    <div className="code-block-container">
      {hasMultipleLines && (
        <div className="code-header">
          <CopyCodeButton code={children} />
        </div>
      )}
      <pre>
        <code className={className}>{children}</code>
      </pre>
    </div>
  );
};

const CustomParagraph = ({ children }) => {
  return (
    <p>
      {React.Children.map(children, (child) =>
        child
      )}
    </p>
  );
};

const CustomLink = ({ children, href }) => {
  const {documents} = useDocumentsContext();
  // Encontrar el documento que tiene una URL que coincide con el href
  const doc = Object.values(documents.documents).find(doc =>
    doc.metadata.url === href || doc.metadata.fulltext_url === href
  );  
  if (doc) {
    // Si el documento existe, puedes usar su `index` y hacer algo con él
    return <CitationIcon number={doc.index} doc={doc} />; // Renderiza CitationIcon con el index como prop, o haz lo que necesites
  } else {
    // Si no se encuentra el documento, renderiza el enlace como un <a>
    return <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>;
  }
};

const MessageTextBox = ({ message }) => {
  return (
    <div className='message-text-box'>
      <ReactMarkdown
        children={message}
        remarkPlugins={[remarkGfm]}
        components={{
          code: CodeBlock,
          p: CustomParagraph,
          li: CustomParagraph, // Custom paragraph component to process text
          a: CustomLink // Custom link component to control link rendering
        }}
      />
    </div>
  );
};

export default MessageTextBox;
