import React, { useState, useEffect } from 'react';
import './ChatInput.css';
import { FiEdit, FiSend } from 'react-icons/fi';
import MessageLoader from '../../loaders/MessageLoader/MessageLoader';
import FilterButton from '../../buttons/FilterButton/FilterButton';
import FormInput from '../FormInput/FormInput';
import FiltersInputModal from '../FiltersInputModal/FiltersInputModal.jsx';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { AiOutlineFileSearch } from "react-icons/ai";

import useDataSource from '../../../hooks/useDataSource';
import {useCollectionsContext} from '../../../contexts/collectionsContext'
import { useMetadatasContext } from '../../../contexts/metadatasContext.js';
import stateToQueryMetadatas from '../../../services/helpers/stateToQueryMetadatas.js';
import { usePreviewContext } from '../../../contexts/previewContext.js';
import { FaPlus } from 'react-icons/fa';
import IntentionButton from '../../buttons/IntentionButton/IntentionButton.jsx';
import ChatInputTools from '../ChatInputTools/ChatInputTools.jsx';
import { useDocumentsContext } from '../../../contexts/documentsContext.js';


const ChatInput = ({ socketRef, loading, disabled=false }) => {
  const [input, setInput] = useState('');
  const [intention, setIntention] = useState('rag_question');
  const [externalSearchToggle, setExternalSearchToggle] = useState(false)
  const [MetadatasToggle, setMetadatasToggle] = useState(false)
  const [ragQuestionToggle, setRagQuestionToggle] = useState(true)
  const [externalSearchFormSendEnabled, setExternalSearchFormSendEnabled] = useState(false)  
  // const [externalSearchFormValues, setExternalSearchFormValues] = useState({source: "", term: "", count:0});
  const [currentResultsCount, setCurrentResultsCount] = useState(0)
  const [currentSourceHref, setcurrentSourceHref] = useState('')
  const [hover, setHover] = useState(false);

  const { preFetchDataSourceQuery, resultsCount, preFetchLoading, preFetchError, sourceHref } = useDataSource();
  const { current_collection } = useCollectionsContext();
  const { metadatas, dispatchMetadatas } = useMetadatasContext();
  const { preview } = usePreviewContext()
  const { documents } = useDocumentsContext()
  const [selectedFile, setSelectedFile] = useState(null);



  const [kwargs, setKwargs] = useState({
    collection_name: current_collection,
    doc_ids: Array.from(documents.selected_documents), 
    metadatas: {},
    related_message: "",
    external_searches: [],
    intention: intention
  });

  // USE EFECTS

  useEffect(() => {
    setCurrentResultsCount(resultsCount)
    if (resultsCount > 0)  {
      setExternalSearchFormSendEnabled(true)
    } else {
      setCurrentResultsCount(0)
    }
  }, [resultsCount]);

  useEffect(() => {
    if (preFetchError)  {
      setCurrentResultsCount(preFetchError)
      setExternalSearchFormSendEnabled(false)
    }
  }, [preFetchError]);

  useEffect(() => {
    if (preFetchLoading)  {
      setCurrentResultsCount('')
      setExternalSearchFormSendEnabled(false)
    }
  }, [preFetchLoading]);

  useEffect(() => {
    setcurrentSourceHref(sourceHref)
  }, [sourceHref])

  useEffect(()=> {
    if (documents.selected_documents) {
      const selected_docs = Array.from(documents.selected_documents)
      // setIntention(selected_docs.length > 0 ? 'doc_question' : 'rag_question');
      setKwargs(prevState => ({
        ...prevState, 
        doc_ids: selected_docs
      }));
    }
  }, [documents.selected_documents])
  
  useEffect(() => {
    console.log("ACTUALIZANDO KWARGS")
  }, [documents])

  useEffect(() => {
    // Actualiza el estado de los kwargs para modificar la colección seleccionada.
    setKwargs(prevState => ({
      ...prevState, 
      collection_name: current_collection
    }));
  }, [current_collection]);

  useEffect(() => {
    // Actualiza el estado de los kwargs para modificar la colección seleccionada.
    setKwargs(prevState => ({
      ...prevState, 
      intention: intention
    }));
  }, [intention]);

  useEffect(() => {
    // Actualiza el estado de los kwargs para modificar la colección seleccionada.
    const query_metadatas = stateToQueryMetadatas(metadatas.selectedMetadatas)
    setKwargs(prevState => ({
      ...prevState, 
      metadatas: query_metadatas
    }));
  }, [metadatas.selectedMetadatas]);
  
  // FUNCIONES
  const sendMessage = () => {
    // Envia el mensaje mediante websocket
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      const messageObject = {
        agent: "general",
        message: input,
        intention: intention,
        kwargs: kwargs,  // Ejemplo de ID de usuario
      };
      socketRef.current.send(JSON.stringify(messageObject));
    } else {
      console.log("WebSocket no está abierto.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && intention.trim()) {
      // onSend(input, intention, kwargs);
      sendMessage()
      setInput(''); 
      setKwargs({
        agent: "general",
        collection_name: current_collection,
        doc_ids: Array.from(documents.selected_documents), 
        metadatas: {},
        related_message: "",
        external_searches: kwargs.external_searches,
        intention: intention
      });
      setIntention('base_question')
      setExternalSearchToggle(false)
    }
  };

  const handleMetadatasToggle = () => {
    setMetadatasToggle((prevState) => {
      const newState = !prevState;
      if (newState) {
        setExternalSearchToggle(false);
      }
      return newState;
    });
  }; 

  const handleIntentionChange = (e) => {
    // Actualiza la intención de la consulta del usuario
    setIntention((prevState) => {
      const newState = e;
      return newState;
    });
  };



  // const handleInputChange = (key, value) => {
  //   setExternalSearchFormValues((prevParams) => {
  //     const updatedValues = { ...prevParams, [key]: value };
  //     fetchFormValues(updatedValues)
  //     return updatedValues;
  //   });
  // };

  // const handleExternalSearchToggle = () => {
  //   setExternalSearchToggle((prevState) => {
  //     const newState = !prevState;
  //     setExternalSearchFormValues({ source: "", term: "", count: 0 });
  //     setCurrentResultsCount(0);
  //     setExternalSearchFormSendEnabled(false);
  //     if (newState) {
  //       setRagQuestionToggle(false);
  //       setIntention(newState ? 'context_question' : 'base_question');
  //     } else {
  //       setIntention(ragQuestionToggle ? 'rag_question' : 'base_question');
  //     }
  //     return newState;
  //   });
  // };
  
  // const handleRemoveExternalSearch = (index) => {
  //   setKwargs((prevState) => ({
  //     ...prevState,
  //     external_searches: prevState.external_searches.filter((_, i) => i !== index)
  //   }));
  // };

  // const handleExternalSearchSubmit = (e) => {
  //   e.preventDefault();
  
  //   // Usa el estado más reciente
  //   setKwargs((prevState) => ({
  //     ...prevState,
  //     external_searches: [
  //       ...(prevState.external_searches || []),
  //       {
  //         source: externalSearchFormValues.source,
  //         term: externalSearchFormValues.term,
  //         count: currentResultsCount
  //       },
  //     ],
  //   }));
  //   setExternalSearchFormValues({source: "", term: "", count:0})
  //   setExternalSearchToggle(false)
  //   setExternalSearchFormSendEnabled(false)
  // };
  
  // const fetchFormValues = (updatedValues) => {
  //   if (updatedValues.source == "" || updatedValues.term == "") {
  //     setExternalSearchFormSendEnabled(false)
  //     return false
  //   } else {
  //     preFetchDataSourceQuery({'query_params':updatedValues, 'data_source':1}) //TODO VARIABLE
  //   }
  // }
  
  const intention_options = {
    base_question: {
      icon: <IoChatboxEllipsesOutline width={50}/>,
      tooltip: "Consulta",
      description: "Consulta en el contexto de la conversación",
      placeholder: "Escriba su consulta...",
      active: true,
    },
    rag_question: {
      icon: <FaMagnifyingGlass width={50}/>,
      tooltip: "Buscar en repositorio",
      description: "Busque respuestas dentro de su repositorio de documentos",
      placeholder: "Consulte a su repositorio de documentos...",
      active: true,
    },
    // external_search: {
    //   icon: <FaMagnifyingGlass width={50}/>,
    //   tooltip: "Buscar en fuentes externas",
    //   description: "Consulta en fuentes externas",
    //   placeholder: "Consulte a su repositorio de documentos...",
    //   active: true,
    // },
    doc_question: {
      icon: <AiOutlineFileSearch width={50}/>,
      tooltip: "Buscar documentos",
      description: "Consulte a los documentos seleccionados",
      placeholder: "Consulte a los documentos seleccionados...",
      active: true,
    },
  };

  return (
    <div className="chat-input-container">
      <MessageLoader loading={loading}/>
      <ChatInputTools
        intention={intention}
        current_collection={current_collection}
        MetadatasToggle={MetadatasToggle}
        handleMetadatasToggle={handleMetadatasToggle}
        metadatas={metadatas}
        // externalSearchFormValues={externalSearchFormValues}
        preFetchLoading={preFetchLoading}
        currentResultsCount={currentResultsCount}
        currentSourceHref={currentSourceHref}
        // handleExternalSearchSubmit={handleExternalSearchSubmit}
        // handleInputChange={handleInputChange}
        externalSearchFormSendEnabled={externalSearchFormSendEnabled}
      />

    <form className="chat-input-form" onSubmit={handleSubmit}>
      <div className="input-row">
        <IntentionButton intention={intention} intention_options={intention_options} onSelect={handleIntentionChange} show={false} />
        <input
          id="input-message"
          type="text"
          className="chat-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={intention_options[intention].placeholder}
          disabled={loading || disabled}
        />
        <div className="actions-input">
          <button type="submit" className="chat-send-btn" disabled={loading || disabled}>
            <FiSend />
          </button>
        </div>
      </div>
    </form>
    
      {/* <button className={`chat-tools-btn ${externalSearchToggle ? 'active' : ''}`} onClick={handleExternalSearchToggle}>Buscar en fuentes externas 🌐</button> */}
    </div>
  );
};

export default ChatInput;
