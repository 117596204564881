// El primer parámetro del reducer es el estado actual, en este caso se renombra como documents.
export default function documentsReducer(state, action) {
  switch (action.type) {
      case "reset":
          return { documents: [], selected_documents: new Set() };

      case "add": {
          const newDocuments = extractDocumentsFromMessage(state.documents, [action.responseMessage ]|| []);
          return {
              documents: { ...state.documents, ...newDocuments },
              selected_documents: state.selected_documents
          };
      }

      case "set": {
          const documents = extractDocumentsFromHistory(action.history);
          return { documents, selected_documents: new Set() };
      }

      case "select": {
          const newSelectedDocuments = selectDocuments(state.documents, action.selectedDocuments, state.selected_documents);
          return { ...state, selected_documents: newSelectedDocuments };
      }

      case "unselect": {
          const newSelectedDocuments = unselectDocuments(state.documents, action.selectedDocuments, state.selected_documents);
          return { ...state, selected_documents: newSelectedDocuments };
      }

      default:
          throw new Error("Unhandled action: " + action.type);
  }
}

function extractDocumentsFromHistory(list) {
  let documentsDict = {};
  let index = 1; // Inicializamos el índice desde 1

  list.forEach(item => {
    let documentsInMsg = item.msg_metadata.documents;

    // Verificamos que `documentsInMsg` sea un array
    if (Array.isArray(documentsInMsg)) {
      documentsInMsg.forEach(doc => {
        // Solo agregamos el documento si aún no está en el diccionario
        if (doc.doc_id && !documentsDict[doc.doc_id]) {
          const newDoc = {
            content: doc.document,
            metadata: doc.metadata
          };
          documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
          documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
        }
      });
    }
  });

  return documentsDict; // Retornamos el diccionario de documentos
}

function extractDocumentsFromMessage(documents, list) {
  let documentsDict = documents;
  let index = Object.keys(documentsDict).length+1;
  
  // Verificamos que `list` no sea `undefined` ni `null` y sea un array
  if (Array.isArray(list)) {
    list.forEach(item => {
      // Verificamos que `item` no sea `undefined` ni `null` y que tenga la propiedad `msg_metadata`
      if (item && item.msg_metadata && Array.isArray(item.msg_metadata.documents)) {
        let documentsInMsg = item.msg_metadata.documents;

        documentsInMsg.forEach(doc => {
          
          // Solo agregamos el documento si tiene un `doc_id` y no está en el diccionario
          if (doc && doc.doc_id && !documentsDict[doc.doc_id]) {
            const newDoc = {
              content: doc.document,
              metadata: doc.metadata
            };
            documentsDict[doc.doc_id] = newDoc; // Asignamos el documento
            documentsDict[doc.doc_id].index = index++; // Ahora asignamos el índice
          }
        });
      }
    });
  }

  return documentsDict; // Retornamos el diccionario de documentos
}

function selectDocuments(documents, selectedDocuments, selectedDocumentsContext) {
  // Creamos un nuevo conjunto para los documentos seleccionados
  const newSelectedDocuments = new Set(selectedDocumentsContext);

  // Marcamos los documentos seleccionados
  selectedDocuments.forEach(selectedDoc => {
    const documentId = selectedDoc.doc_id;
    if (documents[documentId]) {
      newSelectedDocuments.add(documentId);
    }
  });

  return newSelectedDocuments;
}

function unselectDocuments(documents, selectedDocuments, selectedDocumentsContext) {
  // Creamos un nuevo conjunto para los documentos seleccionados
  const newSelectedDocuments = new Set(selectedDocumentsContext);

  // Desmarcamos los documentos seleccionados
  selectedDocuments.forEach(selectedDoc => {
    const documentId = selectedDoc.doc_id;
    if (documents[documentId]) {
      newSelectedDocuments.delete(documentId);
    }
  });

  return newSelectedDocuments;
}
