import React, { createContext, useContext, useEffect, useReducer } from 'react';
import userReducer from '../reducers/userReducer';
import useCurrentUser from '../hooks/useCurrentUser';

// Creamos el contexto
const UserContext = createContext(null);

// Estado inicial para el usuario
let initialUser;
try {
  initialUser = JSON.parse(localStorage.getItem("user")) || {};
} catch {
  console.error("El estado inicial del usuario no pudo ser cargado.");
  initialUser = {};
}

// Proveedor del contexto
export function UserProvider(props) {
  const [user, dispatchUser] = useReducer(userReducer, initialUser);
  const { fetchCurrentUser } = useCurrentUser();

  // Obtener el usuario actual si no hay datos
  useEffect(() => {
    const getUser = async () => {
      if (!user || !user.name) {
        const fetchedUser = await fetchCurrentUser();
        if (fetchedUser) {
          dispatchUser({ type: 'set', payload: fetchedUser });
        }
      }
    };

    getUser();
  }, []);

  // Sincronizar el estado del usuario con localStorage y aplicar tema
  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      localStorage.setItem("user", JSON.stringify(user));
      if (user.theme) {
        document.body.setAttribute('data-theme', user.theme);
      }
      
      const cssConfig = user?.client?.custom_css;
      if (cssConfig && typeof cssConfig === "object") {
        Object.entries(cssConfig).forEach(([key, value]) => {
          document.body.style.setProperty(key, value);
        });
      }
    }
  }, [user]);

  
  const contextValue = {
    user,
    dispatchUser,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}

// Hook personalizado para acceder al contexto
export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserContext debe ser usado dentro de un UserProvider. Envuelve un componente padre con <UserProvider> para resolver este error."
    );
  }
  return context;
}
