import React from "react";

const OnboardingModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-[500px] max-w-full rounded-2xl shadow-xl bg-white">
        <div className="flex justify-between items-start p-4 border-b">
          <h2 className="text-lg font-semibold">
            Tu asistente farmacéutico está listo para ayudarte.
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            x
          </button>
        </div>
        <div className="p-4 space-y-4">
          <p className="text-sm text-gray-700">
            IA-4D es tu compañero confiable para consultas científicas y técnicas en el laboratorio.
            A través de IA-4D, podrás obtener respuestas rápidas y precisas a tus preguntas sobre
            farmacología, normativas, procedimientos y más.
          </p>
          <div className="w-full rounded-lg overflow-hidden border">
            <img
              src="/your-image-path.png"
              alt="Asistente farmacéutico IA-4D"
              className="w-full object-cover"
            />
          </div>
          <div className="flex items-center justify-between text-sm text-gray-500">
            <span>1 de 3</span>
            <button className="bg-emerald-500 hover:bg-emerald-600 text-white">
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
