import { useState } from 'react';
import apiRequest from '../services/api/apiRequest';

const useCurrentUser = () => {
  const [current_user, setCurrentUser] = useState({});

  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar los errores

  const fetchCurrentUser = async () => {
    setLoading(true); // Inicia el estado de carga
    setError(null); // Limpia cualquier error previo

    try {
        setCurrentUser(() => {});
        const { data, error: apiError } = await apiRequest(
            '/users/me',
            'GET',
            'include',
            'application/json',
            null,
            false
        );
        if (apiError) {
            throw new Error(apiError.message);
        }
        setCurrentUser(data);
    } catch (error) {
        console.error('Error fetching current user:', error);
        setError(error.message); // Establece el mensaje de error
    } finally {
        setLoading(false); // Termina el estado de carga
    }
  };

  return {
    current_user,
    loading,
    fetchCurrentUser,
  };
};

export default useCurrentUser;
