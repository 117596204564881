import React, { useState } from 'react';
import CompactedDocumentElement from '../CompactedDocumentElement/CompactedDocumentElement';
import './DocumentGroup.css'; // Add custom styles if necessary
import { usePostHog } from 'posthog-js/react'; // Importar PostHog
import { usePreviewContext } from '../../../contexts/previewContext';
import {useCurrentMessageContext} from '../../../contexts/currentMessageContext'
const documentElementLimit = 6;

const DocumentGroup = ({ documents, remainingDocuments, message }) => {
    const posthog = usePostHog(); // Inicializar PostHog
    const { dispatchPreview } = usePreviewContext()
    const { dispatchCurrentMessage } = useCurrentMessageContext();

    // Manejar el clic en "Ver más"
    const handleViewMoreClick = () => {
        posthog.capture('Fuentes - Ver más documentos', {
            totalDocuments: documents.length,
            displayedDocuments: documentElementLimit,
        });
        dispatchCurrentMessage({ type: 'set', message: message });
        dispatchPreview({ type: 'set', selectedDoc: documents });
        
    };

    // Manejar acceso al panel de fuentes
    const handlePanelAccess = () => {
        posthog.capture('Fuentes - Acceso al panel de fuentes', {
            totalDocuments: documents.length,
        });
    };

    // Determinar documentos visibles
    const visibleDocuments = documents.slice(0, documentElementLimit);

    return (
        <div className="document-group" onClick={handleViewMoreClick}>
            <div className="document-group-elements">
                {remainingDocuments.map((doc) => (
                    <CompactedDocumentElement 
                        key={doc.index} 
                        doc={doc} 
                    />
                ))}
            </div>
            <div className='document-group-text'>
                <b>Ver los {documents.length} resultados</b>
            </div>
        </div>
    );
};

export default DocumentGroup;