import React, { createContext, useContext, useEffect, useReducer } from "react";
import collectionsReducer from "../reducers/collectionsReducer";
import useCollections from "../hooks/useCollections";
import { useUserContext } from "./userContext";

// Crear el contexto
const CollectionsContext = createContext(null);

// Estado inicial
const initialState = { current_collection: {}, collections: [], loading: true, error: null };

// Proveedor del contexto de colecciones
export function CollectionsProvider({ children }) {
  const { collections, loading, fetchCollections, error } = useCollections();
  const [state, dispatchCollections] = useReducer(collectionsReducer, initialState);
  const { user } = useUserContext()

  // Cargar colecciones solo una vez al montar
  useEffect(() => {
    async function loadCollections() {
      try {
        await fetchCollections(); // Esperamos a que se carguen los datos
      } catch (err) {
        console.error("Error fetching collections:", err);
      }
    }
      // EVita que entre en bucle del login si el usuario no esta logueado
    loadCollections();
  }, []); 

  // Actualizar el estado cuando `collections` cambie
  useEffect(() => {
    if (user && collections.length > 0 ) {
      dispatchCollections({ type: "SET_COLLECTIONS", payload: collections });
    }
  }, [collections]); // Se ejecuta solo cuando collections cambia
  
  let context_current_collection
  if (Object.keys(state.current_collection).length === 0) {
    context_current_collection = state.collections[0]
  } else {
    context_current_collection = state.current_collection
  }
  const contextValue = {
    collections: state.collections,
    current_collection: context_current_collection,
    loading,
    error,
    dispatchCollections,
  };
  return <CollectionsContext.Provider value={contextValue}>{children}</CollectionsContext.Provider>;
}

// Hook personalizado para acceder al contexto
export function useCollectionsContext() {
  const context = useContext(CollectionsContext);
  if (!context) {
    throw new Error(
      "useCollectionsContext must be used within a CollectionsProvider. Wrap a parent component in <CollectionsProvider> to fix this error."
    );
  }
  return context;
}
