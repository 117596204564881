import React, { useState } from 'react';
import './ConfigSection.css';
import iconArrowDown from '../../../../assets/images/icons/icon-arrowdown.svg';
import iconArrowUp from '../../../../assets/images/icons/icon-arrowup.svg';
import ManageLinksModal from './Modals/ManageLinksModal/ManageLinksModal';
import ExportDataModal from './Modals/ExportDataModal/ExportDataModal';
import DeleteAccountModal from './Modals/DeleteAccountModal/DeleteAccountModal';
import ChangePasswordModal from './Modals/ChangePasswordModal/ChangePasswordModal';
import { useUserContext } from '../../../../contexts/userContext';

// Componente de Switch
const Switch = ({ isChecked, onToggle }) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
      />
      <span className="slider round"></span>
    </label>
  );
};

const ConfigSection = () => {
  const { user } = useUserContext()
  const [isConfigExpanded, setIsConfigExpanded] = useState(true);
  const [expandedSections, setExpandedSections] = useState({
    General: true,
    Búsquedas: true,
    Privacidad: true,
    Seguridad: true,
  });
  const [notificationsEnabled, setNotificationsEnabled] = useState(user.notifications_enabled);
  const [searchSuggestionsEnabled, setSearchSuggestionsEnabled] = useState(false);
  const [openModal, setOpenModal] = useState(null);

  const toggleConfig = () => {
    setIsConfigExpanded(!isConfigExpanded);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const closeModal = () => setOpenModal(null);

  return (
    <div className="section configuration">
      <div className="title-section">
        <h2>Configuración</h2>
        <button className="toggle-button" onClick={toggleConfig}>
          <img
            src={isConfigExpanded ? iconArrowDown : iconArrowUp}
            alt={isConfigExpanded ? 'Collapse' : 'Expand'}
          />
        </button>
      </div>
      {isConfigExpanded && (
        <div className="config-sections">
          {Object.entries({
            General: (
              <>
                <div className="field first">
                  <label>Idioma</label>
                  <select>
                    <option>Español</option>
                    <option>Inglés</option>
                  </select>
                </div>
                <div className="field label-actions">
                  <label>Habilitar notificaciones</label>
                  <Switch
                    isChecked={user?.config?.notifications_enabled}
                    onToggle={() => setNotificationsEnabled(!notificationsEnabled)}
                  />
                </div>
                <div className="field">
                  <label>Tema</label>
                  <select>
                    {/* <option>Oscuro</option> */}
                    <option>Claro</option>
                  </select>
                </div>
              </>
            ),
            // Búsquedas: (
            //   <div className="field first label-actions">
            //     <label>Ver sugerencias de búsqueda mientras escribes</label>
            //     <Switch
            //       isChecked={searchSuggestionsEnabled}
            //       onToggle={() => setSearchSuggestionsEnabled(!searchSuggestionsEnabled)}
            //     />
            //   </div>
            // ),
            // Privacidad: (
            //   <>
            //     <div className="field first label-actions">
            //       <label>Enlaces compartidos</label>
            //       <button
            //         className="edit-btn-account"
            //         onClick={() => setOpenModal('manageLinks')}
            //       >
            //         Gestionar
            //       </button>
            //     </div>
            //     <div className="field label-actions">
            //       <label>Exportar datos</label>
            //       <button
            //         className="edit-btn-account"
            //         onClick={() => setOpenModal('exportData')}
            //       >
            //         Exportar
            //       </button>
            //     </div>
            //     <div className="field label-actions">
            //       <label>Eliminar mi cuenta y mis datos</label>
            //       <button
            //         className="delete-button"
            //         onClick={() => setOpenModal('deleteAccount')}
            //       >
            //         Eliminar
            //       </button>
            //     </div>
            //   </>
            // ),
            // Seguridad: (
            //   <div className="field first label-actions">
            //     <label>Cambiar contraseña</label>
            //     <button
            //       className="edit-btn-account"
            //       onClick={() => setOpenModal('changePassword')}
            //     >
            //       Cambiar
            //     </button>
            //   </div>
            // ),
          }).map(([title, content]) => (
            <div key={title} className="subsection-config">
              <div className="title-subsection">
                <h3>{title}</h3>
                <button
                  className="toggle-button"
                  onClick={() => toggleSection(title)}
                >
                  <img
                    src={expandedSections[title] ? iconArrowDown : iconArrowUp}
                    alt={expandedSections[title] ? 'Collapse' : 'Expand'}
                  />
                </button>
              </div>
              {expandedSections[title] && (
                <div className="config-section-content">{content}</div>
              )}
            </div>
          ))}
        </div>
      )}
      {/* Renderiza los modales según sea necesario */}
      {openModal === 'manageLinks' && <ManageLinksModal onClose={closeModal} />}
      {openModal === 'exportData' && <ExportDataModal onClose={closeModal} />}
      {openModal === 'deleteAccount' && <DeleteAccountModal onClose={closeModal} />}
      {openModal === 'changePassword' && <ChangePasswordModal onClose={closeModal} />}
    </div>
  );
};

export default ConfigSection;