const formatMetadataValue = (key, value) => {
  const isUrl = key.toLowerCase().includes("url");

  let parsedValue;
  try {
    parsedValue = JSON.parse(value);
  } catch (e) {
    parsedValue = value;
  }

  // Si el valor es un array, dividirlo en un array
  if (Array.isArray(parsedValue)) {

    return (
      <div className="tags-container">
        {parsedValue.map((item, index) => (
          isUrl ? (
            <a
              key={index}
              href={/^https?:\/\//i.test(item) ? item : `https://${item}`}
              target="_blank"
              rel="noopener noreferrer"
              className="tag tag-link"
            >
              {item}
            </a>
          ) : (
            <span key={index} className="tag tag-text">
              {item}
            </span>
          )
        ))}
      </div>
    );
  }

  // Si el valor contiene "||", dividirlo en un array
  if (typeof parsedValue === 'string' && parsedValue.includes("||")) {
    const valuesArray = parsedValue.split("||").map(item => item.trim()); // Dividir y limpiar espacios innecesarios
    return (
      <div className="tags-container">
        {valuesArray.map((item, index) => (
          isUrl ? (
            <a
              key={index}
              href={/^https?:\/\//i.test(item) ? item : `https://${item}`}
              target="_blank"
              rel="noopener noreferrer"
              className="tag tag-link"
            >
              {item}
            </a>
          ) : (
            <span key={index} className="tag tag-text">
              {item}
            </span>
          )
        ))}
      </div>
    );
  }

  // Manejar valores únicos como URL o texto normal
  if (isUrl) {
    const formattedUrl = typeof parsedValue === 'string' && !/^https?:\/\//i.test(parsedValue)
      ? `https://${parsedValue}`
      : parsedValue;
    return (
      <a
        href={formattedUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="tag tag-link"
      >
        {formattedUrl}
      </a>
    );
  }

  // Si el valor es un objeto (diccionario)
  if (typeof parsedValue === 'object' && parsedValue !== null && !Array.isArray(parsedValue)) {
    return (
      <div className="tags-container">
        {Object.entries(parsedValue).map(([subKey, subValue], index) => {
          if (subKey && subKey.trim() !== "" && subValue && typeof subValue === 'string' && subValue.trim() !== "") {
            return (
              <span key={index} className="tag tag-text">
                <strong>{subKey.replace(/_/g, ' ').toUpperCase()}: </strong> {subValue}
              </span>
            );
          }
          return null;
        })}
      </div>
    );
  }
  
  
  return (
    <span className="tag tag-text">
      {parsedValue}
    </span>
  );
};

export default formatMetadataValue;