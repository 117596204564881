import React from 'react';
import './DocumentCarrousel.css';
import DocumentElement from '../DocumentElement/DocumentElement';
import DocumentGroup from '../DocumentGroup/DocumentGroup'; // Import the new component

const DocumentCarrousel = ({ message, documents }) => {
  const visibleDocumentsNumber = 4
  const visibleDocuments = documents.slice(0, visibleDocumentsNumber); // Show the first 5 documents
  const remainingDocuments = documents.slice(visibleDocumentsNumber);  // Group the remaining documents

  return (
    <div className="breadcrumb-container">
      {visibleDocuments.map((doc) => (
        <DocumentElement key={doc.index} message={message} doc={doc} />
      ))}
      
      {remainingDocuments.length > 0 && (
        <DocumentGroup documents={documents} remainingDocuments={remainingDocuments} message={message}/>
      )}
    </div>
  );
};

export default DocumentCarrousel;
