import './Sources.css';

import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/containers/Sidebar/Sidebar';
import Header from '../../components/containers/Header/Header';
import Footer from '../../components/containers/Footer/Footer';
import SourcesArea from '../../components/containers/SourcesArea/SourcesArea';

const Sources = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  useEffect(() => {
  }, []); 

  const toggleSidebar = () => {
    setIsSidebarVisible(prev => !prev);
  };
  return (
  <div className={`chat-container ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
  <Sidebar toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
  <div className={`chat-content ${!isSidebarVisible ? 'full-width' : ''}`}>
    <Header subtitle={"Fuentes de datos"} toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible} />
    <div className={'chat-preview-container full-width'}>
      <SourcesArea/>
    </div>
    <Footer toggleSidebar={toggleSidebar} isSidebarVisible={isSidebarVisible}/>
  </div>
  </div>
  );
};

export default Sources;