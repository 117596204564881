import React, { useState, useEffect } from "react";
import './NewCargaPeriodicaForm.css';
import { RiCloseFill } from "react-icons/ri";
import Select from 'react-select'; // Asegúrate de tener react-select instalado
import iconSearch from '../../../assets/images/icons/icon-search.svg'; // Lupa como icono
import FormInput from "../FormInput/FormInput";
import SimpleLoader from "../../loaders/SimpleLoader/SimpleLoader";
import useDataSource from "../../../hooks/useDataSource";
import getObjectByAttribute from "../../../services/helpers/getObjectByAttribute";

// Datos dummy a la espera de reemplazar por endpoint de API
import sourceSchema from "../../../services/dummy/sourceSchema";
import collectionOptions from "../../../services/dummy/collectionOptions";

const NewCargaPeriodicaForm = ({ show, onClose, existing_id, dataSourceQueries }) => {

  // Opciones para las listas de selección
  const { createDataSourceQueries, fetchDataSourceQuery, updateDataSourceQuery, preFetchDataSourceQuery, sourceHref, resultsCount, preFetchLoading, preFetchError, editedDataSourceQuery } = useDataSource();
  const sourceOptions = sourceSchema.map(({ id, value, label }) => ({ id, value, label }));
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [sourceParams, setSourceParams] = useState({})
  const [fields, setFields] = useState([]);

  // Ejecuta al cargar el componente si proviene del "Edit"
  useEffect(() => {
    if (existing_id) {
      fetchDataSourceQuery(existing_id);
    }
  }, []);

  useEffect(() => {
    if (editedDataSourceQuery) {
      const collection = getObjectByAttribute(editedDataSourceQuery.query_params.collection_name, collectionOptions, "value")
      const source = getObjectByAttribute(editedDataSourceQuery.data_source, sourceSchema, "id")
      setSelectedCollection(collection)
      setSelectedSource(source)
      setSourceParams(editedDataSourceQuery.query_params)
    }
  }, [editedDataSourceQuery]);
  
  useEffect(() => {
    if (selectedSource) {
      // Si hay valores en los fields, colocarlos en el atributo value
      const fields = sourceSchema.find(item => item.value === selectedSource.value)?.fields
      if (editedDataSourceQuery) {
        fields.forEach(item => {
          if (editedDataSourceQuery.query_params[item.name] !== undefined) {
            item.value = editedDataSourceQuery.query_params[item.name];
          }
        });
        updatePreFetchedData(editedDataSourceQuery.query_params)
      }
      setFields(fields);
    }
  }, [selectedSource]);

  // Función para actualizar resultados y enlace
  const updatePreFetchedData = (updatedParams) => {
    preFetchDataSourceQuery({'query_params':updatedParams, 'data_source':selectedSource.id})
  };

  const handleFormInputChange = (key, value) => {
    setSourceParams((prevParams) => {
      const updatedParams = { ...prevParams, [key]: value };
      updatePreFetchedData(updatedParams); // Pasa el estado actualizado
      return updatedParams; // Retorna el nuevo estado
    });
  };  

  const submitForm = async () =>  {
    if (editedDataSourceQuery) {
      const body = {query_params: sourceParams}
      await updateDataSourceQuery(existing_id, body, dataSourceQueries)
    } else {
      createDataSourceQueries(selectedSource.id, selectedCollection, sourceParams)
    }
    onClose()
  }

  return (
    <div className="modal-overlay">
      <div className="modal-cargaperiodica">
        <div className="modal-header">
          <h2>Nueva carga periódica</h2>
          <button className="close-btn" onClick={onClose}>
            <RiCloseFill size={24} />
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-section">
            <p className="modal-question">Fuente principal</p>
            <Select
              options={sourceOptions}
              value={selectedSource}
              onChange={setSelectedSource}
              placeholder="Selecciona una fuente de datos..."
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: 'var(--textfield-bg)',
                  borderColor: 'var(--textfield-border)',
                  color: 'var(--text-primary)',
                  borderRadius: '6px',
                  height: '48px',
                  padding: '0 4px',
                  marginTop: '6px',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: 'var(--text-primary)',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: 'var(--dropdown-bg);',
                  border: 'var(--dropdown-border)',
                  borderRadius: '8px',
                  padding: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'var(--dropdown-option-bg-hover);' : '',
                  color: 'var(--dropdown-option-text)',
                  padding: '10px 12px',
                  borderRadius: state.isFocused ? '6px' : '0',
                  cursor: 'pointer',
                }),
              }}
            />
          </div>
          <div className="modal-section">
            <p className="description-title">Colección</p>
            <Select
              options={collectionOptions}
              value={selectedCollection}
              onChange={setSelectedCollection}
              placeholder="Selecciona una o varias colecciones..."
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: 'var(--textfield-bg)',
                  borderColor: 'var(--textfield-border)',
                  color: 'var(--text-primary)',
                  borderRadius: '6px',
                  height: '48px',
                  padding: '0 4px',
                  marginTop: '6px',
                }),
                singleValue: (base) => ({
                  ...base,
                  color: 'var(--text-primary)',
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: 'var(--dropdown-bg);',
                  border: 'var(--dropdown-border)',
                  borderRadius: '8px',
                  padding: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? 'var(--dropdown-option-bg-hover);' : '',
                  color: 'var(--dropdown-option-text)',
                  padding: '10px 12px',
                  borderRadius: state.isFocused ? '6px' : '0',
                  cursor: 'pointer',
                }),
              }}
            />
          </div>

            {fields ? (
              fields.map((field, index) => (
                <div className="modal-section" key={index}>
                  <FormInput
                    key={index}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    icon={field.icon}
                    initial_value={field.value}
                    options={field.options}
                    isMulti={field.isMulti}
                    placeholder={field.placeholder}
                    helptext={field.helptext}
                    onChange={(event) =>
                      handleFormInputChange(field.name, event.target ? event.target.value : event)
                    }
                  />
                </div>
              ))
            ) : (
              <p>No fields found.</p>
            )}
        </div>

        <div className="modal-footer-left-info">
          <div className="footer-left">
          {preFetchLoading ? (
            <SimpleLoader />
          ) : preFetchError ? (
            <span>{preFetchError}</span>
          ) : (
            <>
              <span>{resultsCount} Resultados</span>
              <a href={sourceHref} target="_blank" className="view-source">
                Ver fuente original
              </a>
            </>
          )}
          </div>
          <div className="footer-right">
            <button className="cancel-btn" onClick={onClose}>Cancelar</button>
            <button className="primary-btn" onClick={submitForm} >Guardar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCargaPeriodicaForm;