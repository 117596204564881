import React, { useEffect, useState } from 'react';
import './DocumentPreview.css'; 
import { usePreviewContext } from '../../../contexts/previewContext';
import getSourceIcon from '../../../services/helpers/getSourceIcon';
import { type } from '@testing-library/user-event/dist/cjs/utility/type.js';
import { usePostHog } from 'posthog-js/react';
import { useDocumentsContext } from '../../../contexts/documentsContext';
import CitationIcon from '../../icons/CitationIcon';
import formatMetadataValue from '../../../services/helpers/formatMetadataValue';

const DocumentPreview = ({ doc }) => {
  const posthog = usePostHog();
  const { doc_id, metadata, content } = doc;
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar la expansión
  const [isDocumentExpanded, setIsDocumentExpanded] = useState(false); // Estado para controlar la expansión
  const { preview, dispatchPreview } = usePreviewContext();
  const [isSelected, setIsSelected] = useState(false);
  const iconSrc = getSourceIcon(metadata.source);
  const {documents, dispatchDocuments} = useDocumentsContext();
  // Encontrar el documento que tiene una URL que coincide con el href
  const index = Object.values(documents.documents).find(doc => doc.metadata.doc_id === doc_id).index;

  useEffect(() => {
    if (doc_id && documents.documents[doc_id]) {
      if (documents.selected_documents.has(doc_id)) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
  }, [documents.selected_documents]);

  // Función para manejar el cambio de estado del checkbox
  const handleCheckboxChange = (doc) => {
    if (isSelected) {
      dispatchDocuments({ type: 'unselect', selectedDocuments: [doc] });
      setIsSelected(false)
      // dispatchPreview({ type: 'unselect', selectedDoc: doc });
    } else {
      dispatchDocuments({ type: 'select', selectedDocuments: [doc] });
      // dispatchPreview({ type: 'select', selectedDoc: doc });
      setIsSelected(true)
    }
     
      if (posthog) {
        posthog?.capture('Fuentes - Selección de fuente', {
          doc_id: doc_id,
          source: metadata.source || 'Fuente desconocida',
          title: metadata.title || 'Sin título',
          selected: isSelected,
        });
      }
  
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded && posthog) {
      posthog?.capture('Fuentes - Visualización fuente', {
        doc_id: doc_id,
        source: metadata.source || 'Fuente desconocida',
        title: metadata.title || 'Sin título',
      });
   }   
  };

  const toggleDocumentExpand = () => {
    setIsDocumentExpanded(!isDocumentExpanded);
    if (!isDocumentExpanded && posthog) {
      posthog?.capture('Fuentes - Visualización documento', {
        doc_id: doc_id,
        source: metadata.source || 'Fuente desconocida',
        title: metadata.title || 'Sin título',
      });
   }   
  };



  const metadataKeys1 = ["creator","subject","url","labeller","protocol","status","identifiers"];
  const metadataKeys2 = ["date","publication_type","journal","pubmed_id","med_id","indication","publisher","study_type","authors","country", "doi","keywords","chemical_registries","chemical_substances"];

  const renderMetadataList = (keys) => (
    <div className="metadata">
      {keys.map((key) => {
        const value = metadata[key]; // Obtener el valor del metadato
        if (!value) return null; // Omitir si el valor es vacío o falso
        
        return (
          <div key={key} style={{ display: 'flex' }}>
            <strong style={{ marginRight: '5px' }}>
              {key
                .replace(/_/g, ' ')          // Reemplaza todos los guiones bajos por espacios
                .replace(/\b\w/g, char => char.toUpperCase())}:
            </strong>
            {formatMetadataValue(key, value)}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className={`document-preview ${isSelected ? 'selected' : ''}`}>
      <div className="document-preview-header">
        <label className="custom-checkbox-container">
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={isSelected}
            onChange={() => handleCheckboxChange(doc)}
          />
          <span className="checkmark"></span>
        </label>
        <img src={iconSrc} alt={metadata.source} className="document-icon" />
        <p className="document-source">{metadata.source}</p>
      </div>

      <h2><CitationIcon number={index}/> {metadata.title || metadata.id || doc_id}</h2>

      {renderMetadataList(metadataKeys1)}


      <div className="document-preview-row">
        <button className="document-button" onClick={toggleExpand}>
          {isExpanded ? 'Ocultar metadatos' : 'Mostrar metadatos'}
        </button>
        <button className="document-button" onClick={toggleDocumentExpand}>
          {isDocumentExpanded ? 'Ocultar documento' : 'Previsualizar documento'}
        </button>

        <div className={`document-preview-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
          {isExpanded && renderMetadataList(metadataKeys2)}
        </div>

        <div className={`document-preview-content ${isDocumentExpanded ? 'expanded' : 'collapsed'}`}>
        
        {isDocumentExpanded && (
          // <iframe 
          //   src={metadata.url} 
          //   onError={(e) => { e.target.style.display = "none"; e.target.nextSibling.style.display = "block"; }}
          //   style={{ display: "block", width: "100%", height: "500px" }} 
          // ></iframe>
          <p style={{ whiteSpace: "pre-line" }}>{doc.content}</p>
        )}

        {isDocumentExpanded && content && (
          <p style={{ display: "none" }}>{content}</p>
        )}


        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;